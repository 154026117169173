import React, { PropsWithChildren } from 'react';
import {
  Statsig,
  useGate as useStatsigGate,
  useExperiment as useStatsigExperiment,
  useConfig as useStatsigConfig,
} from 'statsig-react';

import analytics from 'analytics';

import { useMetadataState } from 'store/metadata';

const ExperimentContext = React.createContext<{
  experiments: Record<string, any>;
  logExperiment: (key: string, details: Record<string, any>) => void;
}>({
  experiments: {},
  logExperiment: () => {},
});

export const ExperimentProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [experiments, setExperiments] = React.useState<Record<string, any>>({});
  return (
    <ExperimentContext.Provider
      value={{
        experiments,
        logExperiment(key, details) {
          if (typeof experiments[key] === 'undefined') {
            setExperiments({ ...experiments, [key]: details });
            analytics.track('Experiment Started', details);
          }
        },
      }}
    >
      {children}
    </ExperimentContext.Provider>
  );
};

export const useExperimentTracking = () => {
  const [metadata] = useMetadataState();

  return {
    logEvent: (
      eventName: string,
      value?: string | number | null | undefined,
      extra?: Record<string, string> | null | undefined,
    ) => {
      if (metadata?.statsig_client_id) {
        return Statsig.logEvent(eventName, value, extra);
      }
    },
  };
};

export const useGate = (key: string): boolean => {
  const gate = useStatsigGate(key);

  return gate.value;
};

export const useExperiment = (
  key: string,
  keepDeviceValue: boolean = false,
  ignoreOverrides?: boolean,
): boolean => {
  const experiment = useStatsigExperiment(key, keepDeviceValue, ignoreOverrides);

  return experiment.config.get('enabled', false);
};

export const useDynamicConfig = (key: string) => {
  const dynamicConfig = useStatsigConfig(key);

  return dynamicConfig.config;
};

export const checkGate = (name: string) => Statsig.checkGate(name);
