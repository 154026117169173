import isPast from 'date-fns/isPast';
import addDays from 'date-fns/addDays';
import { useMemo } from 'react';

import { useMetadataState } from 'store/metadata';
import { useGate } from 'feature-flags/useFeatureFlags';

const useFreeTrialState = () => {
  const isPaymentMethodRequired = useGate('free-trials-payment-method-required');
  const [metadata] = useMetadataState();

  const isFreeTrialPlan = useMemo(() => {
    if (isPaymentMethodRequired) {
      return !!metadata.org?.free_trial_status && metadata.org?.stripe_payment_method_id === null;
    } else {
      return !!metadata.org?.free_trial_status;
    }
  }, [metadata.org?.free_trial_status]);

  const isFreeTrialPlanExpired = useMemo(
    () =>
      isFreeTrialPlan &&
      !!metadata.org?.billing_trial_expiry &&
      isPast(addDays(new Date(metadata.org?.billing_trial_expiry), 1)),
    [metadata.org?.billing_trial_expiry, isFreeTrialPlan],
  );

  return {
    freeTrialQueriesRemaining: metadata.person?.limited_queries_remaining ?? 0,
    isFreeTrialPlanExpired,
    isFreeTrialPlan,
    isPro: metadata.org?.billing_plan_name === 'pro',
  };
};

export default useFreeTrialState;
