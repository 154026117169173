import * as Sentry from '@sentry/browser';
import { AxiosError, AxiosResponse } from 'axios';

import config from 'config';
import { GraphQLError } from 'graphql/error';

import { authCheck } from 'utils/auth-check';
import { isDevelopment } from 'utils/feature-detection';
import { capitalizeFirstLetter } from 'utils/helper';
import { errorNotification } from 'utils/notifications';

type Errno = Omit<keyof typeof ERRNO_MAP, 'default'>;
type ApiError = { errno: Errno; statusCode?: number; error?: string; message?: string };

const HIDE_ERROR_DIALOG_DURATION_MS = 5000;

const ErrorMessage = {
  ALREADY_INVITED: 'An invitation has already been sent to this address.',
  ALREADY_PINNED: 'Entity has already been pinned to your workspace.',
  ALREADY_ONBOARDED: `This account already exists, please try logging in. Please contact support if you need assistance.`,
  ALREADY_VERIFIED: `We couldn't log you in. Please close your browser and try again.`,
  APPROVAL_CONFLICT: 'Approval failed because of a conflict with the target entity.',
  APPROVAL_PENDING: 'An approval request has already been sent.',
  AUTH_METHOD_UNSUPPORTED: 'Please login again using a valid authentication method for this org.',
  BACKOFF: 'Too Many Requests resulted an error. Please retry after a while.',
  BAD_SUBMISSION: 'Your submission had an error. Please check all the fields and try again.',
  BETA_UNAUTHORIZED: 'Beta access has not yet been approved for this domain.',
  CLIENT_OUTDATED: 'A new version of Qatalog is out, please refresh the page.',
  DUPLICATE_NAME: 'The name you chose is already taken. Please choose another and try again.',
  FILE_SIZE: 'File exceeds maximum size. Please try again with a smaller file.',
  FILE_TYPE: 'Sorry, only .gifs, .jpgs, and .pngs are allowed. Please try again.',
  GOAL_ALREADY_IN_PROGRESS: 'Goal is already in progress, can not change the measurement or units.',
  GOAL_KRS_PRESENT:
    'You cannot delete a goal which has key results. Please delete them first and try again.',
  INTEGRATION_NOT_CONNECTED: 'Please connect to this integration before using it.',
  INTEGRATION_UNAUTHORIZED:
    'This integration is not included in your plan. Please contact your admin to upgrade.',
  INTEGRATION_DISABLED:
    'This integration is disabled on Org level. Please contact your admin to enable.',
  INTERNAL: 'An internal error occurred. Our team is going to look into it.',
  INVALID_ACCESS_TOKEN:
    'You do not have access to this area. Please try logging out and logging back in.',
  INVALID_BILLING_ADDRESS:
    'Your billing address is invalid, please update your card below or contact support.',
  INVALID_EMAIL_ADDRESS: 'At least one of the email recipient has an invalid email address.',
  LOGIN_LINK_EXPIRED: 'Login link has expired. Please trying signing in again.',
  LOGIN_WITH_WORK_EMAIL:
    'Please login with your work email. If you tried using your work email and saw this message, please contact support.',
  MIMECAST_USER_NOT_FOUND:
    'Unable to find Mimecast user with your email address. Please get in touch with Qatalog.',
  NETWORK_ISSUE: 'There seems to be a network issue. If the issue persists, please contact us.',
  NOT_AGREED_TERMS: 'Please agree to the terms of service before proceeding.',
  NOT_IMPLEMENTED: 'Not implemented.',
  NOT_EXTERNALIZABLE:
    'Workflow includes some steps that do not allow it be assigned to a non Qatalog user.',
  NOT_FOUND:
    'The entity you are trying to find does not exist. Please make sure you have the correct URL. If the issue persists, please contact us.',
  ORG_MISMATCH: 'You do not have access to this area. Please try again with a different account.',
  ORPHAN_ENTITY: 'You must specify a new owner to remove ownership.',
  ORPHAN_USER: 'Something is wrong with your account. Please contact support for help.',
  PAYMENT_GATEWAY: 'There was an issue with your payment method. Please update it.',
  PAYMENT_SOURCE_MISSING: "You don't have a payment method set. Please add it.",
  PERMISSIONS: 'Sorry, you do not have permission to access that area.',
  PERSON_ARCHIVED:
    'Your account has been deactivated. Please speak to your administrator if you think this was in error.',
  PERSON_ARCHIVED_EMAIL: 'The email address is registered to a deactivated account.',
  PLAN_LIMITS_EXCEEDED:
    'Your organization has reached its plan limits. Please contact your admin to upgrade.',
  RESERVED_SLUG: 'The chosen slug is a reserved keyword. Please change the slug value.',
  SLACK_CHANNEL_PRIVATE:
    'This channel is private. Please invite @qatalog to the channel to share to it.',
  SUBDOMAIN_UNAVAILABLE: 'Your requested subdomain is not available. Please try again.',
  WORKFLOW_STEPS_INVALID:
    'Workflow includes some steps that are invalid. Please contact the admins of the workflow to correct it.',
  ASSIGNMENT_STEPS_INVALID:
    'Workflow assignment became invalid after it was started. Please contact the workflow owner.',
  UNKNOWN: 'An unknown error occurred. Please contact support.',
};

const ERRNO_MAP = {
  100: ErrorMessage.NOT_IMPLEMENTED,
  101: ErrorMessage.INTERNAL,
  102: ErrorMessage.PERMISSIONS,
  103: ErrorMessage.BAD_SUBMISSION,
  104: ErrorMessage.NOT_FOUND,
  106: ErrorMessage.FILE_TYPE,
  107: ErrorMessage.ORPHAN_USER,
  108: ErrorMessage.PERMISSIONS,
  109: ErrorMessage.BETA_UNAUTHORIZED,
  110: ErrorMessage.ALREADY_ONBOARDED,
  111: ErrorMessage.LOGIN_WITH_WORK_EMAIL,
  114: ErrorMessage.ORG_MISMATCH,
  115: ErrorMessage.ALREADY_INVITED,
  119: ErrorMessage.ALREADY_INVITED,
  122: ErrorMessage.INVALID_ACCESS_TOKEN,
  123: ErrorMessage.NOT_AGREED_TERMS,
  125: ErrorMessage.PERMISSIONS,
  127: ErrorMessage.PERMISSIONS,
  128: ErrorMessage.PERMISSIONS,
  129: ErrorMessage.LOGIN_WITH_WORK_EMAIL,
  130: ErrorMessage.ALREADY_VERIFIED,
  131: ErrorMessage.LOGIN_WITH_WORK_EMAIL,
  132: ErrorMessage.INTEGRATION_NOT_CONNECTED,
  133: ErrorMessage.FILE_SIZE,
  140: ErrorMessage.PLAN_LIMITS_EXCEEDED,
  141: ErrorMessage.PLAN_LIMITS_EXCEEDED,
  142: ErrorMessage.INTEGRATION_UNAUTHORIZED,
  146: ErrorMessage.PAYMENT_GATEWAY,
  147: ErrorMessage.PAYMENT_SOURCE_MISSING,
  155: ErrorMessage.LOGIN_WITH_WORK_EMAIL,
  156: ErrorMessage.INTEGRATION_NOT_CONNECTED,
  157: ErrorMessage.APPROVAL_PENDING,
  159: ErrorMessage.SUBDOMAIN_UNAVAILABLE,
  160: ErrorMessage.SUBDOMAIN_UNAVAILABLE,
  162: ErrorMessage.ALREADY_PINNED,
  165: ErrorMessage.DUPLICATE_NAME,
  166: ErrorMessage.LOGIN_LINK_EXPIRED,
  167: ErrorMessage.GOAL_KRS_PRESENT,
  169: ErrorMessage.PERSON_ARCHIVED,
  170: ErrorMessage.PERSON_ARCHIVED_EMAIL,
  171: ErrorMessage.APPROVAL_CONFLICT,
  173: ErrorMessage.INVALID_EMAIL_ADDRESS,
  179: ErrorMessage.PERMISSIONS,
  180: ErrorMessage.PERMISSIONS,
  181: ErrorMessage.ORPHAN_ENTITY,
  182: ErrorMessage.GOAL_ALREADY_IN_PROGRESS,
  186: ErrorMessage.CLIENT_OUTDATED,
  187: ErrorMessage.NOT_EXTERNALIZABLE,
  188: ErrorMessage.SLACK_CHANNEL_PRIVATE,
  189: ErrorMessage.MIMECAST_USER_NOT_FOUND,
  190: ErrorMessage.WORKFLOW_STEPS_INVALID,
  214: ErrorMessage.RESERVED_SLUG,
  216: ErrorMessage.INVALID_BILLING_ADDRESS,
  217: ErrorMessage.AUTH_METHOD_UNSUPPORTED,
  229: ErrorMessage.ASSIGNMENT_STEPS_INVALID,
  254: ErrorMessage.INTEGRATION_DISABLED,
  413: ErrorMessage.FILE_SIZE,
  // fallback
  default: ErrorMessage.UNKNOWN,
};

export const RETRY_STATUS_CODES = new Set([408, 409, 424, 425, 502, 503, 504]);

const COMMON_ERROR_STATUS_CODES = new Set([400, 403]);

const PAELLA_ERRNOS = new Set([209, 144, 145]);

const INTERNAL_ERRNOS = new Set([101]);

const COMMON_ERRNOS = new Set([104, 147]);

export const onErrorMessage = (err: string, retryFn?: () => void) => {
  errorNotification({
    content: err,
    config: {
      // Close/hide the error dialog after 5 seconds in order to keep the UI clean
      autoClose: HIDE_ERROR_DIALOG_DURATION_MS,
      // Don't show duplicate errors
      toastId: typeof err === 'string' ? err : undefined,
    },
    ...(!!retryFn
      ? {
          cta: {
            label: 'Retry',
            onClick: retryFn,
          },
        }
      : {}),
  });
};

export const silenceError = (error: AxiosError<ApiError>) => {
  return (
    error.response?.data?.errno !== 147 &&
    error.response?.status !== 401 &&
    error.response?.status !== 502 &&
    !!error.config?.url &&
    (error.config.url.startsWith('/api/notifications') ||
      error.config.url.startsWith('/api/activities'))
  );
};

/**
 * Log a REST endpoint error and display a Toast message to the user,
 * extracting human-readable message from the error.
 *
 * For GraphQL errors, [utils/client#graphQLErrorHandlingLink](./client.ts)
 *
 * @param {*} error
 */
export const handleApiError = (
  error: AxiosResponse<ApiError> | AxiosResponse<ApiError>['data'],
) => {
  // graphql errors are handled by graphQLErrorHandlingLink in utils/client

  const axiosError = 'data' in error ? error?.data : null;

  if (axiosError) {
    // errors from axios arrive wrapped in a `data` property on a response object
    onErrorMessage(translateErrno(axiosError));
  }

  if (!error) return;

  captureErrorOnSentry(axiosError ?? error);
};

/**
 * Handler function for network request errors, taking appropriate logging, user notification,
 * authentication, and other actions based on the type and content of the error. To be used in
 * interceptor functions for REST and GraphQL clients.
 */
export const handleRequestError = ({
  statusCode,
  data,
  errno,
  params,
  retryFn,
  handleFn,
  message = '',
  // Following used for orchestration error handling in particular
  config,
}: {
  statusCode?: number;
  data?: any;
  errno?: number;
  params?: any[];
  retryFn?: () => void;
  handleFn: () => void;
  message?: string;
  config?: any;
}) => {
  /**
   * PRIVATE SLACK CHANNEL ERROR
   * Fail silently if the user can't access it.
   */
  if (errno === 188) return;

  /**
   * PAELLA AI ERROR
   * Fail silently, we have dedicated UI for this.
   */
  if (errno && PAELLA_ERRNOS.has(errno)) return;

  /**
   * ARCHIVED ORGS WITH NO PAYMENT METHOD
   * More context here: https://app.shortcut.com/qatalog/story/39932/422s-from-settings-subscriptions-for-archived-orgs-are-leaking-through-to-sentry
   */
  if (errno === 147) return;

  /**
   * KNOWN INTEGRATIONS ERRORS
   * Notify without logging.
   */
  if (config?.url?.includes?.('/api/ecosystem/orchestration')) {
    onErrorMessage(
      translateErrno({
        errno,
        params,
        hideErrno: false,
        provider: config.url.split('/')[4],
      }),
    );
    return;
  }

  /**
   * MANAGE CONNECTION ERRORS
   * Notify without logging.
   */
  if (config?.url?.includes?.('/api/custom-sources') && errno === 257) {
    return;
  }

  /**
   * INTERNAL ERROR
   * Likely due to malformed network request or bad implementation.
   * Logged on Backend. Notify without logging unless unrecognized errno,
   * in which case fall back to error's handler.
   */
  if (statusCode === 500) {
    if (errno && INTERNAL_ERRNOS.has(errno)) {
      onErrorMessage(
        translateErrno({
          errno,
          params,
          hideErrno: false,
        }),
      );
    }

    return;
  }

  /**
   * RETRY ERRORS
   * Due to infra or network conditions. Notify and attempt again when appropriate.
   */
  if (statusCode && RETRY_STATUS_CODES.has(statusCode)) {
    onErrorMessage(ErrorMessage.NETWORK_ISSUE, retryFn);
    return;
  }

  /**
   * BACKOFF ERRORS
   * Too many requests. Notify without logging.
   */
  if (statusCode === 429) {
    let errorMessage = '';
    if (data?.duration_ms && message && message?.indexOf('`duration_ms`') !== -1) {
      const durationSeconds = `${Math.floor(data.duration_ms / 1000)} seconds`;
      errorMessage = message.replace('`duration_ms`', durationSeconds);
    } else {
      errorMessage = message || ErrorMessage.BACKOFF;
    }

    onErrorMessage(errorMessage);
    return;
  }

  /**
   * USER COUNT EXCEEDS QUOTA
   * Notify without logging.
   */
  if (statusCode === 402) {
    onErrorMessage(message);
    return;
  }

  /**
   * COMMON ERRORS
   * Notify without logging.
   */
  if (
    (statusCode && COMMON_ERROR_STATUS_CODES.has(statusCode)) ||
    (errno && COMMON_ERRNOS.has(errno))
  ) {
    // TODO: Maybe use errno to filter instead of status code
    // Backend knows exactly whats happening and we want to show that to the user
    onErrorMessage(
      translateErrno({
        errno,
        params,
        hideErrno: false,
      }),
    );
    return;
  }

  /**
   * UNAUTHORIZED ERROR
   * Check authentication status and redirect/refresh as necessary
   */
  if (statusCode === 401 && document.visibilityState === 'visible') {
    authCheck();
  }

  handleFn();
};

export const captureErrorOnSentry = (error: unknown, contexts = {}) => {
  let sentryError;

  if (error instanceof Error) {
    sentryError = error;
  } else if (
    error instanceof GraphQLError &&
    error?.extensions?.code &&
    (error as GraphQLError).message
  ) {
    // Apollo Error
    sentryError = new Error(
      `${(error as GraphQLError).extensions.code} ${(error as GraphQLError).message}`,
    );
  } else if (error && 'errno' in (error as ApiError)) {
    // Axios Error
    sentryError = new Error(
      `${(error as ApiError).statusCode} ${(error as ApiError).error}: ${
        (error as ApiError).message
      } (errno ${(error as ApiError).errno})`,
    );

    if (config.envConfig.sentryExcludedErrorCodes.includes((error as ApiError).errno as any)) {
      return;
    }
  } else {
    sentryError = error;
  }

  if (isDevelopment()) {
    console.error(sentryError); // eslint-disable-line no-console
    return;
  }

  Sentry.captureException(sentryError, {
    contexts: { ...contexts, full_error: error as any },
  });
};

export const translateErrno = ({
  errno,
  params = [],
  hideErrno = false,
  provider = null,
}: {
  errno?: Errno;
  params?: any[];
  hideErrno?: boolean;
  provider?: string | null;
} = {}) => {
  if (provider && errno !== 254) {
    return `Something went wrong. ${capitalizeFirstLetter(
      provider,
    )} is having issues at the moment.`;
  }

  let message = (errno && ERRNO_MAP[errno as keyof typeof ERRNO_MAP]) ?? ErrorMessage.UNKNOWN;

  if (!!params?.length) {
    message = `Something went wrong. Please double check the ${params
      .map(({ key }) => capitalizeFirstLetter(key.replace(/_/g, ' ')))
      .join(', ')
      .replace(/, ([^,]*)$/, ' and $1')} and try again.`;
  }

  if (isNaN(errno as number)) {
    return message;
  }

  return hideErrno ? message : `${message} (E${errno})`;
};

const CircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: {} | null) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }

      seen.add(value);
    }

    return value;
  };
};

export const promiseRejectionEventToError = (event: PromiseRejectionEvent) => {
  return new Error(
    `UNHANDLED PROMISE REJECTION: ${JSON.stringify(
      {
        ...event,
        // Non-enumerable
        reason: event.reason,
      },
      CircularReplacer(),
    )} visiting ${window?.location?.href}`,
  );
};
