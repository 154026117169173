import styled, { css } from 'styled-components/macro';
import { Flex } from '@qatalog/mosaiq-ui';

export const OnboardingStepsIndicator = styled(Flex).attrs({ alignItems: 'center', gap: 1 })`
  position: fixed;
  top: ${({ theme }) => theme.spacing(6)}px;
  right: ${({ theme }) => theme.spacing(6)}px;
  background-color: ${({ theme }) => theme.mosaiq.colors.white};
  padding: ${({ theme }) => theme.spacing(1)}px;
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  z-index: 10;

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'small' })`
    top: ${theme.spacing(3)}px;
    right: ${theme.spacing(2)}px;
  `};
`;

export const OnboardingStepsIndicatorBullet = styled.div<{ active: boolean }>`
  min-width: ${({ theme }) => theme.spacing(1)}px;
  min-height: ${({ theme }) => theme.spacing(1)}px;
  max-width: ${({ theme }) => theme.spacing(1)}px;
  max-height: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.mosaiq.colors.passive};
  opacity: ${({ active }) => (active ? 1 : 0.3)};
`;

export const OnboardingContainer = styled.div`
  width: 100%;
  min-width: 300px;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.mosaiq.colors.white} 32%, #F4F2F0 100%)`};
`;

export const OnboardingLogo = styled.a<{ light?: boolean }>`
  position: fixed;
  top: ${({ theme }) => theme.spacing(6)}px;
  left: ${({ theme }) => theme.spacing(6)}px;
  width: 125px;
  height: 30px;
  z-index: 10;

  svg {
    width: 125px;
    height: 30px;

    ${({ light, theme }) =>
      !!light &&
      css`
        path {
          fill: ${theme.mosaiq.colors.white};
        }
      `};
  }

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'small' })`
    top: 0;
    left: 0;
    height: auto;
    padding: ${theme.spacing(3)}px;
    padding-bottom: 0;
    width: 100%;
    box-shadow: 0px 0px 30px 30px rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 0.95);

    svg {
      width: 97px;
      height: auto;
    }
  `};
`;

export const OnboardingStepsContent = styled.div<{ isFullView?: boolean; scrollable?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(14)}px 12vw;

  & > div {
    width: 100%;
    margin: 0 auto;
    min-height: ${({ isFullView }) => !isFullView && '200px'};
    max-width: 1000px;
    overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'visible')};
  }

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'small' })`
    padding: ${theme.spacing(12)}px ${theme.spacing(3)}px;

    & > div {
      padding-bottom: ${theme.spacing(10)}px
    }
  `};
`;

export { default as OnboardingColumnsLayout } from './OnboardingColumnsLayout';
export { default as OnboardingInput } from './OnboardingInput';
export { default as OnboardingLabel } from './OnboardingLabel';
export { default as OnboardingError } from './OnboardingError';
export { default as OnboardingLoading } from './OnboardingLoading';
export { default as OnboardingOrgPicker } from './OnboardingOrgPicker';
export { default as OnboardingFooter } from './OnboardingFooter';
export { default as OnboardingLogoContainer } from './OnboardingLogoContainer';
