import { Org, Person, PersonMinimal, Team, Entity } from 'types';
import { Resource } from 'mosaiq/resources';

import { Task } from '../tasks';
import { Thread } from 'mosaiq/threads/types';
import { WorkflowAssignment } from 'workflows';
import { Workflow } from 'mosaiq/workflows';
import type { Person as MosaiqPerson } from 'mosaiq/people';
import { Label } from '../labels';
import { CustomEntity } from 'mosaiq/custom-entities/types';
import { Trigger } from 'mosaiq/triggers';
import { Measurement } from 'types/api';
import { Page } from 'mosaiq/pages-modal/types';

export enum EntityType {
  CUSTOM = 'entity_instances',
  LABELS = 'labels',
  MEASUREMENTS = 'measurements',
  ORG = 'org',
  PAGES = 'pages',
  PEOPLE = 'people',
  RESOURCES = 'resources',
  TASKS = 'tasks',
  TEAMS = 'teams',
  THREADS = 'threads',
  TRIGGERS = 'triggers',
  WORKFLOW_ASSIGNMENTS = 'workflow_assignments',
  WORKFLOWS = 'workflows',
}

export enum SingularEntityType {
  CUSTOM = 'entity_instance',
  MEASUREMENTS = 'measurement',
  PAGES = 'page',
  PEOPLE = 'person',
  RESOURCES = 'resource',
  TASKS = 'task',
  TEAMS = 'team',
  THREADS = 'thread',
  TRIGGERS = 'trigger',
  WORKFLOW_ASSIGNMENTS = 'workflow_assignment',
  WORKFLOWS = 'workflow',
  ORG = 'org',
}

export type EntityStatus =
  | 'at_risk'
  | 'completed'
  | 'on_hold'
  | 'on_track'
  | 'setup'
  | 'in_progress'
  | 'open';

export type TypedMeasurement = {
  entityType: EntityType.MEASUREMENTS;
  entity?: Measurement & {
    entity: Entity;
  };
};

export type TypedPage = {
  entityType: EntityType.PAGES;
  entity?: Page | Page;
};

export type TypedPerson = {
  entityType: EntityType.PEOPLE;
  entity?: Person | PersonMinimal | MosaiqPerson; // TODO: remove old Person type after full mosaiq migration
};

export type TypedResource = {
  entityType: EntityType.RESOURCES;
  entity?: Resource;
};

export type TypedTask = {
  entityType: EntityType.TASKS;
  entity?: Task;
};

export type TypedTeam = {
  entityType: EntityType.TEAMS;
  entity?: Team;
};

export type TypedThread = {
  entityType: EntityType.THREADS;
  entity?: Thread;
};

export type TypedTrigger = {
  entityType: EntityType.TRIGGERS;
  entity?: Trigger;
};

export type TypedWorkflow = {
  entityType: EntityType.WORKFLOWS;
  entity?: Workflow;
};

export type TypedWorkflowAssignment = {
  entityType: EntityType.WORKFLOW_ASSIGNMENTS;
  entity?: WorkflowAssignment;
};

export type TypedOrg = {
  entityType: EntityType.ORG;
  entity?: Org;
};

export type TypedLabel = {
  entityType: EntityType.LABELS;
  entity?: Label;
};

export type TypedCustomEntity = {
  entityType: EntityType.CUSTOM;
  entity?: CustomEntity;
};

export type TypedEntity =
  | TypedMeasurement
  | TypedPage
  | TypedPerson
  | TypedResource
  | TypedTask
  | TypedTeam
  | TypedThread
  | TypedTrigger
  | TypedWorkflow
  | TypedWorkflowAssignment
  | TypedOrg
  | TypedLabel
  | TypedCustomEntity;
