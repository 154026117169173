export enum FormStep {
  USER_DETAILS = 'userDetails',
}

export enum Step {
  SIGNUP = 'freeTrialSignupOrg',
  SIGNUP_COMPLETED = 'signupOrgCompleted',
  FREE_TRIAL_REQUESTED = 'freeTrialRequested',
  FREE_TRIAL_REJECTED = 'freeTrialRejected',
  FREE_TRIAL_APPROVED = 'freeTrialApproved',
}

export type FormFields = {
  orgName: string;
  orgSubdomain: string;
  companySize: string;
  useCasesText: string;
  jobFunction: string;
  jobRole: string;
  preferredInstanceType: string;
  acceptTerms: boolean;
};
